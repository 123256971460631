//rich editor field
ui.formFieldRichEditor = function(){
	var scope = ".js-form-field-rich-editor";

    function createTinyMCEOptions($field,$textarea){
        var opc =
            {
                "target":$textarea.get(0),
                "body_class": "web-u-gvml",
                "statusbar" : false,
                "menubar" : false,
                "language": "en",
                "language_url" : '/nodemdls/tinymce-i18n/langs/es.js',
                "plugins": "pagebreak nonbreaking searchreplace,charmap,lists,table,wordcount,emoticons",
                "entity_encoding" : "named",
                "convert_urls" : false,
                "toolbar": "",
                "emoticons_database": 'emojis',
                "content_css": "/css/web.min.css",
                "setup" : function(ed) {
                    ed.ui.registry.addButton('anchor',anchorButton(ed));
                    ed.ui.registry.addButton('link',linkButton(ed,$field));
                    ed.ui.registry.addButton('title',titleButton(ed,"",$field));
                    ed.ui.registry.addButton('subtitle',titleButton(ed,"sub",$field));
                    ed.ui.registry.addButton('newLine',newLineButton(ed));
                    ed.ui.registry.addButton('image',imageButton(ed,$field));
                    ed.ui.registry.addButton('icon',iconButton(ed,$field));
                    ed.ui.registry.addButton('video',videoButton(ed,$field));
                    ed.on(
                        'change',
                        function(){
                            tinymce.triggerSave();
                        }
                    );
                    ed.on(
                        'init',
                        function(){
                            // Init language if exists
                            if($field.data().langValues !== undefined &&
                                $field.data().lang !== undefined &&
                                $field.data().langValues[$field.data().lang] !== undefined){
                                    var langval = $field.data().langValues[$field.data().lang];
                                    var $input = $field.find("textarea");
                                    $input.val(langval);
                                    ed.setContent(langval);
                            }

                            $field.data(
                                "funcSetVal",
                                function(val){
                                    var $input = $field.find("textarea");
                                    var setFunction = function(){
                                        var editorObject = tinymce.get($input.attr("id"));
                                        if(editorObject){
                                            editorObject.setContent(val);
                                            $input.val(val);
                                            $field.trigger("formField:changeVal");
                                        }
                                    };

                                    //protect from uninitialized tinymce
                                    if(window.tinymce){
                                        var editorObject = tinymce.get($input.attr("id"));
                                        if(editorObject){ //available editor
                                            setFunction();
                                            return;
                                        }
                                    }

                                    //no tiny or no instance: wait
                                    setTimeout(
                                        function(){
                                            setFunction();
                                        },
                                        1000
                                    );
                                }
                            );
                        }
                    );
                }
            };

        opc.toolbar1 = "bold underline italic forecolor backcolor removeformat | image icon | blocks fontfamily fontsize | link unlink anchor | alignleft aligncenter alignright alignjustify | undo redo search replace pastetext | subscript superscript | charmap | bullist numlist outdent indent ";
        opc.valid_elements = "a[href|title],span[style|class],strong,em,sub,sup,br,img[src|class|width|height|title],div[style]";
        if($field.data("editor-type")=="content"){
            opc.toolbar1 += " | title subtitle bullist numlist newLine";
            opc.valid_elements += ",p[style],ul,ol,li,h1[class|style],h2[class|style],h3[class|style],h4[class|style],h5[class|style]";
        }else if($field.data("editor-type")=="email"){
            opc.toolbar1 += " | title subtitle bullist numlist ";
            opc.valid_elements += ",p[style],ul,ol,li,h1[class|style],h2[class|style],h3[class|style],h4[class|style],h5[class|style]";
        }else{
            opc.force_p_newlines = false;
            opc.forced_root_block = '';
        }
        return opc;
    }

    function newLineButton(ed){
        return {
            title : 'Insert line break',
            tooltip : 'Insert line break',
            icon: 'page-break',
            onAction : function(){
                ed.selection.setContent(ed.selection.getContent({format : 'raw'})+"<br />");
            }
        };
    }

    function anchorButton(ed){
        return {
            title : 'Insert anchor',
            tooltip : 'Insert anchor',
            icon : 'bookmark',
            onAction : function(){
                swal({
                    title: "Insert anchor",
                    type: "input",
                    showCancelButton: true,
                    closeOnConfirm: true,
                    inputPlaceholder: "Anchor name",
                    inputValue: "Check"
                }, function (inputValue) {
                    if (inputValue === false) return false;
                    if (inputValue === "") {
                        swal.showInputError("Name cannot be empty");
                        return false
                    }
                    ed.selection.setContent("<img class=\"ancla ancla"+inputValue+"\" title=\"#"+inputValue+"\" src=\"/img/cp/editor/anchor.gif\" />");
                    return true;
                });
            }
        };
    }

    function linkButton(ed,$field){
        return {
            title : 'Link selected text',
            tooltip : 'Link selected text',
            icon: 'link',
            onAction : function(){
                var $modal = $field.find(scope+"__link-modal").clone().appendTo($("body"));
                ui.init($modal);
                var $sel = $(ed.selection.getNode());
                if($sel.attr("href")){
                    $modal.find(".js-form-field[data-name=link]").formFieldSetVal(atob($sel.attr("href")));
                }else{
                    $sel = null;
                }
                $modal.modal("show");
                $modal.find(scope+"__save-link").click(
                    function(ev){
                        $modal.modal("hide");
                        gvf.endpoint(
                            "\\controller\\web\\webController::getLinkDescription",
                            [$modal.find("[name=link]").val()],
                            function(resp){
                                if(resp.status=="ok"){
                                    if($sel){
                                        $sel.attr("href",resp.base64);
                                        $sel.attr("data-mce-href",resp.base64);
                                        $sel.attr("title",resp.description);
                                    }else{
                                        ed.selection.setContent("<a href=\""+resp.base64+"\" title=\""+resp.description+"\">"+ed.selection.getContent({format : 'raw'})+"</a>");
                                    }
                                    ed.save();
                                }
                            }
                        );
                    }
                );
            }
        };
    }

    function insertMultimediaElement(ed,$field,fileTypeId,alignment){
        listPage.selectModelItem(
            "RepFile",
            function(id){
                var fInsertElement = function(align){
                    if(fileTypeId==2){ //video
                        var size = "";
                        if(align!="full"){
                            size = " width='280' height='192'";
                        }
                        var img = "<img src=\"/img/cp/editor/video-placeholder.jpg\" class=\"vid vid"+id+" "+align+"\""+size+" />";
                    }else{
                        gvf.endpoint(
                            "cp\\repFileVersionController::getImageUrlByAlignment",
                            [id,align],
                            function(resp){
                                if(resp.status=="ok"){
                                    var img = "<img src=\""+resp.url+"\" class=\"img img"+id+" "+align+"\" />";
                                    ed.selection.setContent(img);
                                    ed.save();
                                }
                            }
                        );
                    }
                };

                if(!alignment){
                    var $modal = $field.find(scope+"__align-modal").clone().appendTo($("body"));
                    $modal.modal("show");
                    $modal.find(scope+"__align-modal-link").click(
                        function(ev){
                            $modal.modal("hide");
                            fInsertElement($(this).data("value"));
                        }
                    );
                }else
                    fInsertElement(alignment);
            }
        );
    }

    function imageButton(ed,$field,alignment){
        return {
            title : 'Insert image',
            tooltip : 'Insert image',
            icon : 'gallery',
            onAction : function(){
                insertMultimediaElement(ed,$field,1,alignment);
            }
        };
    }

    function iconButton(ed,$field){
        return {
            title : 'Insert icon',
            tooltip : 'Insert icon',
            icon: 'emoji',
            onAction : function(){
                ed.execCommand('mceEmoticons');
                ed.save();
            }
        };
    }

    function videoButton(ed,$field,alignment){
        return {
            title : 'Insert video',
            tooltip : 'Insert video',
            icon: 'embed',
            onAction : function(){
                insertMultimediaElement(ed,$field,2,alignment);
            }
        };
    }

    function titleButton(ed,sub,$field){
        return {
            title : 'Insert '+sub+'title',
            tooltip : 'Insert '+sub+'title',
            icon: (sub.length==0) ? 'text-size-increase' : "text-size-decrease",
            onAction : function() {
                var $modal = $field.find(scope+"__title-modal").clone().appendTo($("body"));
                var $sel = $(ed.selection.getNode());
                if(!$sel.is("h1,h2,h3,h4,h5")){
                    $sel = null;
                }
                $modal.modal("show");
                $modal.find(scope+"__title-modal-link").click(
                    function(ev){
                        $modal.modal("hide");
                        var selectedLevel = $(this).data("level");
                        var txt = ed.selection.getContent({format : 'text'});
                        if(txt.length==0)
                            txt = "Title text";
                        if($sel){
                            txt = $sel.text();
                        }
                        var html = "<h"+selectedLevel+" class=\"web-u-gvml__"+sub+"title\">"+txt+"</h"+selectedLevel+">";
                        if($sel){
                            $sel.replaceWith(html);
                        }else{
                            ed.selection.setContent(html);
                        }
                        ed.save();
                    }
                );
            }
        };
    }

	ui.ready(
		scope,
		function($field){
		    var $input = $field.find("textarea");
		    $field.data(
                "funcGetVal",
                function(){
                    return tinymce.get($input.attr("id")).getContent({format : 'raw'});
                }
            );

            if($input.attr("rows")>0)
                $input.css("height",$input.attr("rows")+"em");

            gvf.loadJsScript(
                "/nodemdls/tinymce/tinymce.min.js",
                function(){
                    setTimeout(
                        function(){
                            tinymce.baseURL = "/nodemdls/tinymce";
                            tinymce.init(createTinyMCEOptions($field,$input));
                        },
                        500
                    );
                }
            );
		}
	);
}();