//form fields
ui.formFieldInput = function(){
	var scope = ".js-form-field-color-picker";
	var tt;

	ui.ready(
		scope,
		function($field){
		    var $value = $field.find(scope+"__value");
            $field.data(
                "funcGetVal",
                function(){
                    return $value.val();
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    $value.asColorPicker("set",val);
                    $field.trigger("formField:changeVal");
                }
            );

            $value.on(
                "asColorPicker::change",
                function(){
                    $field.trigger("formField:changeVal");
                }
            );

            $value.asColorPicker({"readonly":$field.data("edit")!=1});
		}
	);
}();