var cpDropzone = function(){
    var scope = ".js-dropzone";

    function loadFiles(dzObject,files){
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            file.accepted = true;
            dzObject.files.push(file);
            dzObject.emit('addedfile', file);
            dzObject.createThumbnailFromUrl(file, file.url,null,"anonymous");
            dzObject.emit('complete', file);
        }
    }

    ui.ready(
        scope,
        function($dropzone){
            //prevent multiple initializations
            if($dropzone.data("dropzone")){
                return;
            }
            $dropzone.addClass("dropzone");
            var isReadonly = $dropzone.hasClass("is-readonly");
            var isSingleFile = $dropzone.hasClass("is-single-file");
            var isSortable = $dropzone.hasClass("is-sortable");
            var $input = $("[name='"+$dropzone.data("input")+"']");
            var config = $dropzone.data("config");
            var fileTypeId = $dropzone.data("filetypeid");

            if(isReadonly)
                config.dictDefaultMessage = "";
            config.url = "/files/upload/";
            if(fileTypeId)
                config.url += fileTypeId+"/";
            config.addRemoveLinks = !isReadonly;
            config.init = function() {
                this.on(
                    "success",
                    function(ev,response){
                        if(response.status=="ok" && response.files && response.files.length>0){
                            if(isSingleFile){
                                $input.val(response.files[0]);
                            }else{
                                var files = [];
                                if($input.val()){
                                    files = JSON.parse($input.val());
                                }
                                files = files.concat(response.files);
                                $input.val(JSON.stringify(files));
                            }
                            if(response.files.length==1){
                                ev.id = response.files[0];
                            }
                            $dropzone.trigger("dropzone:change");
                        }
                    }
                );
                this.on(
                    "addedfile",
                    function(file) {
                        file.previewElement.addEventListener("click",function(ev){
                            if(ev.target.nodeName=="SPAN" && ev.target.attributes["data-dz-name"] && !isReadonly){
                                swal({
                                    title: "Change name",
                                    type: "input",
                                    showCancelButton: true,
                                    closeOnConfirm: true,
                                    cancelButtonText: "Cancel",
                                    inputPlaceholder: "File name",
                                    inputValue: file.name
                                }, function (inputValue) {
                                    if (inputValue === false) return false;
                                    if (inputValue === "") {
                                        swal.showInputError("Name cannot be empty");
                                        return false
                                    }
                                    gvf.endpoint(
                                        "controller\\cp\\repFileController::changeFileName",
                                        [file.id,inputValue],
                                        function(resp){
                                            if(resp.status!="ok"){
                                                utils.alertEndpointResponse(resp);
                                            }else{
                                                $(file.previewElement).find("[data-dz-name]").html(inputValue);
                                                file.name = inputValue;
                                            }
                                        }
                                    );
                                    return true;
                                });
                            }else{
                                if(file.detailUrl){
                                    if(file.detailUrl.indexOf("?d=1")==-1)
                                        window.open(file.detailUrl);
                                    else
                                        window.location = file.detailUrl;
                                }
                            }
                        });
                    }
                );
                this.on(
                    "removedfile",
                    function(ev){
                        if(ev.id){
                            if(isSingleFile){
                                if($input.val()==ev.id){
                                    $input.val("");
                                }
                            }else{
                                var files = [];
                                if($input.val()){
                                    files = JSON.parse($input.val());
                                }
                                var i = files.indexOf(ev.id);
                                if(i==-1){
                                    i = files.indexOf(parseInt(ev.id));
                                }
                                if(i>=0){
                                    files.splice(i,1);
                                }
                                $input.val(JSON.stringify(files));
                            }
                            $dropzone.trigger("dropzone:change");
                        }
                    }
                );
                var files = $dropzone.data('files');
                loadFiles(this,files);
                var dz = this;
                $dropzone.data('dropzone',dz);
                if(isSortable && !isReadonly){
                    new Sortable(
                        $dropzone.get(0),
                        {
                            draggable: ".dz-complete",
                            onEnd: function (ev) {
                                if($.isArray(dz.files)){
                                    var value = dz.files.splice(ev.oldIndex,1)[0];
                                    dz.files.splice(ev.newIndex,0,value);
                                    var ids = [];
                                    for(var i in dz.files){
                                        if(dz.files[i].id){
                                            ids.push(dz.files[i].id);
                                        }
                                    }
                                    $input.val(JSON.stringify(ids));
                                    $dropzone.trigger("dropzone:change");
                                }
                            }
                        }
                    );
                }
            };
            if($dropzone.data('dropzone')){ //prevent double initialization
                $dropzone.data('dropzone',null);
                var el = $dropzone.get(0);
                if(el){
                    el.dropzone = null;
                    if(el.removeEventHandler){
                        el.removeEventHandler("click");
                    }
                }
            }
            $dropzone.dropzone(config);
            if(isReadonly){
                $dropzone.css("pointer-events","none").find(".dz-preview").css("pointer-events","auto");
            }
        }
    );

    //extend jquery
    jQuery.fn.extend(
        {
            "dropzoneSetValue":function(value){
                if(this.is(scope)){
                    var dz = this.data('dropzone');
                    if(dz){
                        if(!value){
                            dz.removeAllFiles();
                            this.data("files",[]);
                        }else{
                            gvf.endpoint(
                                "web\\repUploadFileController::getFilesInfo",
                                [value],
                                function(resp){
                                    if(resp.files !== undefined){
                                        loadFiles(dz,resp.files);
                                    }
                                }
                            );
                        }
                    }
                }
            }
        }
    );
}();