//form fields
ui.formField = function(){
	var scope = ".js-form-field";

    //Shows errors on field level field
    function showFieldError($field,errors){
        var $error = $field.find(scope+"__error");
        if($.isArray(errors))
            errors = errors.join("<br />");

        $field.find(scope+"__error").html("");
        if(errors){
            $field.addClass("has-error");
            $error.html(errors);
        }else{
            $field.removeClass("has-error");
        }
    }

    /**
     * Checks field restrictions
     * @param $field
     * @param contextual
     * @returns {boolean} If field has errors
     */
    function checkFieldRestrictions($field,contextual){
        var validation = $field.data("restrictions");
        if(!$.isPlainObject(validation)){
            return true;
        }
        var errField = contextual?"contextualErrorMessage":"errorMessage";
        var val = $field.formFieldVal();
        var errors = [];
        if(validation.required){
            if(val === undefined || val == null || val.length <= 0){
                errors.push(validation.required[errField]);
            }
        }
		if(validation.regexp){
			if(val && val.length>0){
				var regexp = new RegExp(validation.regexp.regexp);
				if(!regexp.test(val)){
                    errors.push(validation.regexp[errField]);
                }
			}
		}
        if(validation.callback){
            if(val && val.length>0){
                var additionalParams = validation.callback.additionalParams;
                var params = [];
                if(additionalParams && additionalParams.length>0)
                    params = additionalParams.slice(); //clone array
                params.splice(0,0,val);
                var response = gvf.endpointSync(validation.callback.function,params);
                if(response.status!="ok"){
                    errors.push(response.messages?response.messages[0]:response);
                }
            }
        }
        if(validation.length){
            if(val && val.length>0){
                if(val.length>validation.length.max){
                    errors.push(validation.length[errField]);
				}
            }
        }
        if(validation.range){
            if(val && val.length>0){
                if(val<validation.range.min || val>validation.range.max){
                    errors.push(validation.range[errField]);
                }
            }
        }
        if(validation['min-value']){
            if(val && val.length>0){
                if(parseFloat(val)<parseFloat(validation['min-value'].min)){
                    errors.push(validation['min-value'][errField]);
                }
            }
        }
        if(validation['max-value']){
            if(val && val.length>0){
                if(parseFloat(val)>parseFloat(validation['max-value'].max)){
                    errors.push(validation['max-value'][errField]);
                }
            }
        }
        showFieldError($field,errors);
		return (errors.length==0);
    }

    /**
     * Initializes lang switcher in field
     * @param $field
     */
    function initMultiLangSwitcher($field){
        var languages = $field.data("langs");
        var langValues = $field.data("lang-values");
        var currentFieldName = $field.data("name");
        var icons = "";
        //add cloned field for each lang with name +_lang_xx and set value
        for(var i in languages){
            var lang = languages[i];
            var $langField = $field.clone();
            var newName = currentFieldName+"_lang_"+lang;
            var langFlagIcon = 'flag-icon-'+lang;
            if(lang === 'en'){
                langFlagIcon = 'flag-icon-gb';
            }

            $langField.data("langs",null);
            $field.data("lang-"+lang+"-field",$langField);
            $langField.attr("data-lang",lang);
            $langField.find("[name='"+currentFieldName+"']").attr("name",newName);
            $langField.find("[name='"+currentFieldName+"__rich-editor']").attr("name",newName+"__rich-editor");
            $langField.find("[data-input='"+currentFieldName+"']").attr("data-input",newName);
            //change duplicate ids
            $langField.find("[id]").each(
                function(){
                    $(this).attr("id",newName+$(this).attr("id"));
                }
            );

            $langField.insertAfter($field);
            ui.init($langField);
            $langField.hide();
            $langField.setRequireField(false);
            $langField.formFieldSetVal("");
            if(langValues && langValues[lang]){
                $langField.formFieldSetVal(langValues[lang]);
            }
            $langField.prepend('<i class="flag-icon '+langFlagIcon+'"></i> ');

            icons += '<i class="cp-form-field__lang-switcher-icon js-form-field__lang-switcher-icon flag-icon '+langFlagIcon+'" data-lang="'+lang+'"></i> ';
        }
        $field.prepend($("<div />").addClass("cp-form-field__lang-switcher").html(icons));
        $field.find(scope+"__lang-switcher-icon").click(
            function(){
                var thisLang = $(this).data("lang");
                var $langField = $field.data("lang-"+thisLang+"-field");
                $langField.toggle();
                if($langField.is(":visible")){ //hide other languages fields
                    for(var i in languages){
                        if(thisLang!=languages[i]){
                            $field.data("lang-"+languages[i]+"-field").hide();
                        }
                    }
                }
            }
        ).each(
            function(){
                $(this).css("opacity",langValues && langValues[$(this).data("lang")]?1:0.2);
            }
        );
    }

    ui.ready(
    	scope,
		function($field){
            $field.on(
            	"formField:checkRestrictions",
				function(ev){
            		checkFieldRestrictions($(this),true);
				}
			);

            $field.find(":input").on(
                "invalid",
                function(ev){
                    ev.preventDefault();
                    var restrictionsPassed = checkFieldRestrictions($field,true);
                    if (!ev.target.validity.valid && restrictionsPassed) {
                        showFieldError($field,ev.target.validationMessage);
                    }
                }
            );

            //initialize bootstrap tooltips
            $field.find('[data-toggle="tooltip"]').tooltip();

            if($field.data("langs")){
                initMultiLangSwitcher($field);
            }
        }
	);

	//extend jquery to get values
	jQuery.fn.extend(
		{
			"formFieldVal":function(){
				if(this.is(scope)){
                    if(this.data("funcGetVal")) //if field has get value function
                        return this.data("funcGetVal")();
				}
				return undefined;
			},
            "formFieldSetVal":function(val){
                if(this.is(scope)){
                    if(this.data("funcSetVal")) //if field has get value function
                        return this.data("funcSetVal")(val);
                }
            },
            "formFieldCheckRestrictions":function(contextual){
                if(this.is(scope)){
                    return checkFieldRestrictions(this,contextual);
                }
                return false;
            },
            "formFieldShowError":function(errors){
                if(this.is(scope)){
                    showFieldError(this,errors);
                }
            }
		}
	);

	return {};
}();