ui.formFieldSelect = function(){
	var scope = ".js-form-field-select-discount";

	ui.ready(
		scope,
        function($field){
		    var $select = $field.find(scope+"__select");
            $field.data(
                "funcGetVal",
                function(){
                    return $select.val();
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    $select.find("[value='"+val+"']").attr("selected","selected");
                    $field.trigger("formField:changeVal");
                }
            );

            $select.change(
                 function(){
                    $field.trigger("formField:changeVal");
                    var valueSelect = $select.val();
                    if(valueSelect == 1){
                        swal({
                            icon: 'warning',
                            title: '¡Caution!',
                            text: 'This option should not be used for promotional conditions, it could cause the system to crash',
                            confirmButtonText: 'Accept',
                            confirmButtonColor: '#fc4b6c',
                        });
                    }

                }
            );

            if($select.attr("readonly")){
                $select.css("pointer-events","none");
            }
		}
	);
}();
