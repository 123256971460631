//form fields
ui.formFieldDate = function(){
	var scope = ".js-form-field-checks";

	ui.ready(
		scope,
		function($field){
			var $checks = $field.find(scope+"__check");
            var $search = $field.find(scope+"__search");
            $checks.click(
                function(ev){
                    $field.trigger("formField:changeVal");
                }
            );

            $field.data(
                "funcGetVal",
                function(){
                    var values = [];
                    $checks.filter(":checked").each(
                        function(){
                            values.push($(this).val());
                        }
                    );
                    return values;
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    //todo set val
                }
            );

            $search.keyup(
                function(){
                    if($search.val()==""){
                        $field.find(scope+"__check-container").show();
                    }else{
                        gvf.delay(
                            500,
                            function(){
                                var s = $search.val().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g,"");
                                console.log(s);
                                $field.find(scope+"__check-container").each(
                                    function(){
                                        var t = $(this).text().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g,"");
                                        $(this).toggle(t.indexOf(s)>-1);
                                    }
                                );
                            }
                        );
                    }
                }
            );
		}
	);
}();