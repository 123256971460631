//form fields
ui.formFieldFiles = function(){
	var scope = ".js-form-field-files";

	ui.ready(
		scope,
		function($field){
			var $dropZone = $field.find(scope+"__files");
            var $input = $field.find(scope+"__input");

            $field.data(
                "funcGetVal",
                function(){
                    return $input.val();
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    $dropZone.dropzoneSetValue(val);
                    $input.val(val);
                }
            );
		}
	);
}();