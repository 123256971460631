ui.form = function(){
    var scope = ".js-form";
    var oppositeAction = {show:"hide",hide:"show",enable:"disable",disable:"enable",require:"optional",optional:"require"};

    function setRequireField($target,isRequired){
        $target.toggleClass("is-required",isRequired);
        $target.find(":input").prop("required",isRequired);
        var r = null;
        if(isRequired){
            r = $target.data("restrictions") || {};
            r.required = {errorMessage:"This field is required"};
            r.required.contextualErrorMessage = r.required.errorMessage;
        }else{
            r = $target.data("restrictions") || {};
            delete r.required;
        }
        $target.data("restrictions",r);
    }

    function applyBindingAction($target,action){
        var isField = $target.hasClass("js-form-field");
        var wasVisible = $target.is(":visible");
        var wasDisabled = $target.hasClass("is-disabled");
        var wasRequired = $target.hasClass("is-required");
        switch(action){
            case "show":
                if(!$target.data("lang")){ //multi-lang fields never show on binding
                    $target.show();
                }
                if(isField && $target.data("wasrequired")){ //if field was required, require it again
                    setRequireField($target,true);
                }
                if(!wasVisible){
                    $target.trigger("formField:bindShow");
                }
                break;
            case "hide":
                $target.hide();
                //hidden fields cannot be required
                if(isField){
                    $target.data("wasrequired",wasRequired);
                    setRequireField($target,false);
                }else if($target.is("option") && $target.prop("selected")){
                    $target.closest("select").val("");
                }
                if(wasVisible){
                    $target.trigger("formField:bindHide");
                }
                break;
            case "enable":
                $target.removeClass("is-disabled");
                if($target.is("option")){
                    $target.prop("disabled",false);
                }else{
                    $target.find(":input").prop("disabled",false);
                }
                if(wasDisabled){
                    $target.trigger("formField:bindEnable");
                }
                break;
            case "disable":
                $target.addClass("is-disabled");
                if($target.is("option")){
                    if($target.prop("selected")){ //was the selected option: empty selection
                        $target.closest("select").val("");
                    }
                    $target.prop("disabled",true);
                }else{
                    $target.find(":input").prop("disabled",true);
                }
                if(!wasDisabled){
                    $target.trigger("formField:bindDisable");
                }
                break;
            case "require":
                setRequireField($target,true);
                if(!wasRequired){
                    $target.trigger("formField:bindRequire");
                }
                break;
            case "optional":
                setRequireField($target,false);
                if(wasRequired){
                    $target.trigger("formField:bindOptional");
                }
                break;
        }
    }

    //Apply bindings response to given form
    function applyBindings($form,bindingsResponse){
        var appliedSelector = "[data-applied-binding]";
        var $appliedElements = $form.find(appliedSelector).not($form.find(scope+" "+appliedSelector));
        for(var i in bindingsResponse){
            var binding = bindingsResponse[i];
            //pick form fields and linked blocks
            //avoid nested fields
            var nameSelector = "[data-name='"+binding.field+"']";
            var bindAsSelector = "[data-bind-as='"+binding.field+"']";
            var $target = $form.find(nameSelector+","+bindAsSelector).not($form.find(scope+" "+nameSelector+","+scope+" "+bindAsSelector));
            $appliedElements = $appliedElements.not($target);
            $target.each(
                function(){
                    applyBindingAction($(this),binding.action);
                }
            );
            $target.attr("data-applied-binding",binding.action);
        }

        $appliedElements.each(
            function(){
                var $el = $(this);
                var previousAction = $el.attr("data-applied-binding");
                applyBindingAction($el,oppositeAction[previousAction]);
            }
        );
    }

    //Check bindings response to form
    function checkBindings($form){
        var bindings = getCurrentBindings($form);
        applyBindings($form,bindings);
    }

    //Get current applying bindings response from form
    function getCurrentBindings($form){
        var bindingsResponse = [];
        var selector = "[data-bind]";
        //avoide nested fields
        $form.find(selector).not($form.find(scope+" "+selector)).each(
            function(){
                var $el = $(this);
                var bindings = $el.data("bind");
                var results = {};
                for(var bindingIndex in bindings){
                    var binding = bindings[bindingIndex];
                    var match = false;
                    if(binding.field){
                        var $other = $form.find("[data-name='"+binding.field+"']");
                        var val = $other.formFieldVal();
                        if(binding.values && $.isArray(binding.values)){
                            if(!$.isArray(val)){
                                val = [val];
                            }
                            for(var i=0;i<val.length;i++){
                                var v = val[i];
                                if(v){
                                    if(binding.values.indexOf(v)>-1 || binding.values.indexOf(v.toString())>-1 || binding.values.indexOf(parseInt(v))>-1){
                                        match = true;
                                        break;
                                    }
                                }
                            }
                        }else if(binding.range && $.isArray(binding.range)){
                            if(
                                (binding.range[0]===null || val>=binding.range[0]) &&
                                (binding.range[1]===null || val<=binding.range[1])
                            ){
                                match = true;
                            }
                        }else if(binding.regexp){
                            var regexp = new RegExp(binding.regexp);
                            if(regexp.test(val)){
                                match = true;
                            }
                        }else{
                            match = val?true:false;
                        }

                        var name = $el.data("name");
                        if(!name)
                            name = $el.data("bind-as");

                        var action = (match?binding.action:oppositeAction[binding.action]);
                        bindingsResponse.push({"field":name,"action":action});

                        /* APPLY OPERATORS */
                        if(binding.operator){
                            var key = name+"_"+binding.action;
                            if(!results[key]){
                                results[key] = {result:match,items:[],action:binding.action};
                            }
                            //store current response index to access it later
                            results[key].items.push(bindingsResponse.length-1);
                            if(binding.operator=="or"){
                                results[key].result = results[key].result || match;
                            }else{
                                results[key].result = results[key].result && match;
                            }
                        }
                    }
                }

                //apply logic operators result
                for(var key in results){
                    for(var i in results[key].items){
                        var action = (results[key].result?results[key].action:oppositeAction[results[key].action]);
                        bindingsResponse[results[key].items[i]].action = action;
                    }
                }
            }
        );
        return bindingsResponse;
    }

    //Shows messages in given form
    function showErrorMessages($form,messages,isError){
        var $messages = $form.find(scope+"__messages:first");
        $messages.empty();
        if(messages.length>0){
            for(var i=0;i<messages.length;i++){
                var $message = $("<div />").addClass("alert alert-"+(isError?"danger":"primary"));
                $message.html(messages[i]);
                $messages.append($message);
            }
            $form.addClass("is-showing-messages");
        }else{
            $form.removeClass("is-showing-messages");
        }
    }

    //Applies validation response to form
    function applyValidationResponse($form,validation){
        if(validation && validation.validation){
            validation = validation.validation;
        }

        var errors = [];
        if(validation && validation.fields && validation.fields.length>0){
            for(var i=0;i<validation.fields.length;i++){
                var selector = "[data-name='"+validation.fields[i].field+"']";
                var $field = $form.find(selector);
                //avoid nested fields
                $field.not($form.find(scope+" "+selector));

                var err = [];
                var info = validation.fields[i].info;
                for(var j=0;j<info.length;j++){
                    err.push(info[j].contextualErrorMessage?info[j].contextualErrorMessage:info[j].errorMessage);
                    errors.push(info[j].errorMessage);
                }
                $field.formFieldShowError(err);
            }
        }
        if(validation.global && validation.global.length>0){
            for(var i=0;i<validation.global.length;i++)
                errors.push(validation.global[i]);
        }
        showErrorMessages($form,errors,true);
    }

    //Check fields restrictions for given form
    function checkRestrictions($form){
        var isOk = true;
        $form.find(".js-form-field").each(
            function(){
                if($(this).closest(scope).get(0)==$form.get(0)){ //avoid nested forms
                    var response = $(this).formFieldCheckRestrictions(true);
                    if(!response){
                        isOk = false;
                    }
                }
            }
        );
        return isOk;
    }

    ui.ready(
        scope,
        function($form){
            $form.on(
                "formField:changeVal",
                function(ev){
                    gvf.delay(300,function(){
                        $form.checkBindings();
                    });
                }
            );
            $form.checkBindings();
            if($form.data("validation-response")){
                applyValidationResponse($form,$form.data("validation-response"));
            }
        }
    );

    //extend functionality for forms
    jQuery.fn.extend(
        {
            "getCurrentBindings":function(){
                return getCurrentBindings(this);
            },
            "checkBindings":function(){
                return checkBindings(this);
            },
            "checkRestrictions":function(){
                return checkRestrictions(this);
            },
            "applyValidationResponse":function(response){
                applyValidationResponse(this,response);
            },
            "applyBindings":function(bindingsResponse){
                return applyBindings(this,bindingsResponse);
            },
            "setRequireField":function(isRequired){
                if(this.is(".js-form-field")){
                    setRequireField(this,isRequired);
                }
            }
        }
    );

}();