//form fields
ui.formFieldInput = function(){
	var scope = ".js-form-field-input";
	var tt;

	ui.ready(
		scope,
		function($field){
            var $check = $field.find("[type=checkbox]");
            var isCheck = ($check.length>0);
            var isHidden = $field.is("input");
            var $input = $field.find(":input");
            var isTimeInput = ($input.attr("type")=="time");

            $field.data(
                "funcGetVal",
                function(){
                    if(isHidden)
                        return $field.val();
                    else if(isCheck)
                        return $check.get(0).checked;
                    else
                        return $field.find(":input").val();
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    if(isHidden)
                        $field.val(val);
                    else if(isCheck)
                        $check.get(0).checked = val?true:false;
                    else
                        $field.find(":input").val(val);
                    $field.trigger("formField:changeVal");
                }
            );

            $input.on(
                "change",
                function(){
                    $field.trigger("formField:changeVal");
                }
            );

            $input.keyup(
                function(ev){
                    tt = gvf.delay(
                        500,
                        function(){
                            $field.trigger("formField:checkRestrictions",{"input":$(this)});
                        }
                    );
                }
            ).blur(
                function(){
                    if(tt){
                        clearTimeout(tt);
                        tt = null;
                    }
                    $field.trigger("formField:checkRestrictions",{"input":$(this)});
                }
            );

            if(isTimeInput){
                $input.clockpicker({
                    placement: $field.offset().top>250?'top':'bottom',
                    align: 'left',
                    autoclose: true,
                    'default': 'now'
                });
            }

            try{
                if((typeof $input.data("inputmask")) !== "undefined" && $input.data("inputmask") !== undefined && $input.data("inputmask") !== "undefined" && $input.data("inputmask") !== ""){
                    console.log("Currency field init...");
                    if((typeof Inputmask)==="function" && (typeof Inputmask.extendAliases)==="function"){
                        Inputmask.extendAliases(
                            {
                                numeric: {
                                    onBeforeMask: function(value){
                                        if(!isNaN(value)){
                                            return parseFloat(value).toFixed(this.opts.digits?this.opts.digits:0).toString().replace(".",this.opts.radixPoint);
                                        }else{
                                            return value;
                                        }
                                    },
                                    onUnMask: function(maskedValue,unmaskedValue){
                                        if(unmaskedValue || unmaskedValue.length>0){
                                            const groupSeparatorEscaped = this.opts.groupSeparator.replace(/[.*+?^${}()|[\]\\]/g,'\\$&');
                                            return maskedValue.replace(new RegExp(groupSeparatorEscaped,'g'),"").replace(this.opts.radixPoint,".").replace(/[^0-9\\.-]/g,"");
                                        }else{
                                            return "";
                                        }
                                    }
                                }
                            }
                        );
                    }

                    Inputmask().mask($input.get(0));
                }
            }catch(e){
                console.log("Currency Field dimension err: "+e);
            }
		}
	);
}();