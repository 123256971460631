var utils = function(){
    /**
     * Creates an alert based on an endpoint response
     * @param {*} response
     */
    function alertEndpointResponse(response){
        var type = "error";
        if(response.status=="ok")
            type = "success";

        var message = "";
        if(response.messages)
            message = response.messages.join("<br />");
        else if(response.message)
            message = response.message;
        else if(response)
            message = response;

        if($.type(message) !== 'string'){
            message = "Error";
        }

        if(message.length<300){
            swal(
                {
                    type: type,
                    title: "",
                    text: message
                }
            );
        }else{
            $modal = $('<div class="modal" tabindex="-1"><div class="modal-dialog modal-dialog-scrollable" role="document"><div class="modal-content"><div class="modal-body">'+message.replace(/\n/,"<br />")+'</div></div></div></div>');
            $("body").append($modal);
            $modal.modal("show");
        }
    }

    return {
        "alertEndpointResponse":alertEndpointResponse
    };
}();