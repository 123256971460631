//form fields
ui.formFieldModelSelector = function(){
	var scope = ".js-form-field-model-selector";

	ui.ready(
		scope,
		function($field){
			var $input = $field.find(scope+"__input");
            var $info = $field.find(scope+"__info");
            var $value = $field.find(scope+"__value");
            var $modal = $field.find(scope+"__modal").clone().appendTo($("body"));
            var $suggest = $field.find(scope+"__suggest");
            var $modalBody = $modal.find(scope+"__modal-body");

            function getCopySeed(){
                var seed = [$field.data("endpoint")];
                if($field.data("model"))
                    seed.push($field.data("model"));
                if($field.data("multiple"))
                    seed.push($field.data("multiple"));
                return JSON.stringify(seed);
            }

            function copyValue(itemIndex){
                var v = $field.formFieldVal();
                if(itemIndex!==undefined){
                    v = v[itemIndex];
                }
                if(v){
                    var key = getCopySeed();
                    localStorage[key] = JSON.stringify(v);
                }
            }

            function setValue(value,noEvent){
                if($field.data("multiple") && $.isArray(value)){
                    value = JSON.stringify(value);
                }
                $value.val(value);
                if($field.data("multiple")){
                    value = value?JSON.parse(value):[];
                }
                $field.toggleClass("has-value",$value.val()?true:false);
                $field.toggleClass("has-no-value",$value.val()?false:true);
                $modal.modal("hide");
                gvf.endpoint(
                    $field.data("endpoint")+"ModelSelectorInfo",
                    [$field.data("model"),value,$field.data("multiple"),$field.hasClass("is-editable"),$field.data()],
                    function(resp){
                        if(resp.status=="ok"){
                            $info.html(resp.html);
                            $info.find(scope+"__item-remove").click(
                                function(){
                                    var $item = $(this).closest(scope+"__item");
                                    removeValue($item.data("id"));
                                    $item.css("opacity",0.5);
                                }
                            );
                            $info.find(scope+"__item-edit,"+scope+"__item-view,"+scope+"__item-clone").click(
                                function(){
                                    var $item = $(this).closest(scope+"__item");
                                    var isEdit = $(this).is(scope+"__item-edit");
                                    var isView = $(this).is(scope+"__item-view");
                                    detailPage.openModal(
                                        $field.data("model"),
                                        $item.data("id"),
                                        !isView,
                                        null,
                                        function(id){
                                            if(isEdit){ //edited item (refresh)
                                                setValue($field.formFieldVal(),true);
                                            }else{ //created item (add)
                                                addValue(id);
                                            }
                                        },
                                        $field.data(),
                                        null,
                                        function($detail){
                                            if(!isEdit && !isView){
                                                $detail.find("[name=id]").val("");
                                            }
                                        }
                                    );
                                }
                            );
                            $info.find(scope+"__item-delete").click(
                                function(){
                                    var $item = $(this).closest(scope+"__item");
                                    detailPage.deleteObject(
                                        $field.data("model"),
                                        $item.data("id"),
                                        null,
                                        function(){
                                            removeValue($item.data("id"));
                                        }
                                    );
                                }
                            );
                            if($field.data("multiple") && $field.data("sortable")){
                                var sortable = new Sortable(
                                    $info.find(scope+"__items").get(0),
                                    {
                                        onEnd: function (ev) {
                                            var fieldValue = $field.formFieldVal();
                                            if($.isArray(fieldValue)){
                                                var value = fieldValue.splice(ev.oldIndex,1)[0];
                                                fieldValue.splice(ev.newIndex,0,value);
                                                $field.formFieldSetVal(fieldValue);
                                            }
                                        }
                                    }
                                );
                            }
                            ui.init($info);
                        }
                    }
                );
                if(!noEvent)
                    $field.trigger("formField:changeVal");
            }

            //store function reference to load from outside
            $modal.data("setValue",setValue);
            $modal.data("addValue",addValue);
            $field.data("openModal",openModal);
            $field.data("copyValue",copyValue);

            function addValue(id,noEvent){
                if($field.data("multiple")){
                    var newIds = id;
                    if(!$.isArray(id)){
                        newIds = [id];
                    }

                    var ids = [];
                    if($value.val())
                        ids = JSON.parse($value.val());

                    for(var i in newIds){
                        if(ids.indexOf(newIds[i])==-1)
                            ids.push(newIds[i]);
                    }
                    setValue(JSON.stringify(ids),noEvent);
                }else
                    setValue(id,noEvent);
            }

            function removeValue(id,noEvent){
                if($field.data("multiple") && id!==undefined){
                    id = id.toString();
                    var ids = [];
                    if($value.val()){
                        ids = JSON.parse($value.val());
                    }
                    var index = ids.indexOf(id);
                    if(index==-1 && !isNaN(id)){
                        index = ids.indexOf(parseInt(id));
                    }
                    if(index>-1){
                        ids.splice(index,1);
                    }
                    setValue(ids.length>0?JSON.stringify(ids):null,noEvent);
                }
            }

            function emptyValue(){
                $input.val("");
                setValue("");
            }

            function openModal(search,enableLoading){
                $modal.modal("show");
                if(enableLoading)
                    $modal.addClass("is-loading");
                var fieldData = $field.data();
                fieldData.form = $field.closest(".js-form").getValues();
                //attach field data to modal
                $modal.data("fieldData",fieldData);
                $modal.data("refreshModal",function(){
                    openModal(search);
                });
                gvf.endpoint(
                    $field.data("endpoint")+"ListContent",
                    [$field.data("model"),search,0,null,$field.data(),"model-selector"],
                    function(resp){
                        $modal.removeClass("is-loading");
                        if(resp.status=="ok"){
                            $modalBody.html(resp.html);
                            $modalBody.find('.js-search-form').submit(
                                function(ev){
                                    ev.preventDefault();
                                    openModal($(this).getValues());
                                }
                            );
                            ui.init($modalBody);
                        }
                    }
                );
            }

            $field.data(
                "funcGetVal",
                function(){
                    if($value.val()){
                        try{
                            return JSON.parse($value.val());
                        }catch(e){ //might be a plain string value (char id for example)
                            return $value.val();
                        }
                    }else{
                        return null;
                    }
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    setValue(val);
                }
            );

            $field.find(scope+"__empty").click(
                function(ev){
                    ev.preventDefault();
                    if($field.data("parent-model-id-field")){
                        var value = $field.formFieldVal();
                        if(value){
                            detailPage.deleteObject(
                                $field.data("model"),
                                value,
                                null,
                                function(){
                                    emptyValue();
                                }
                            );
                        }
                    }else{
                        emptyValue();
                    }
                }
            );

            $field.find(scope+"__search").click(
                function(ev){
                    ev.preventDefault();
                    openModal(null,true);
                }
            );

            $field.find(scope+"__create,"+scope+"__edit").click(
                function(ev){
                    ev.preventDefault();
                    var isEdit = $(this).is(scope+"__edit");
                    detailPage.openModal(
                        $field.data("model"),
                        isEdit?$value.val():null,
                        true,
                        null,
                        function(id){
                            if($field.data("multiple")){
                                addValue(id); //always add item (no edit option in multiple)
                            }else{ //edited or created (set)
                                setValue(id);
                            }
                        },
                        $field.data()
                    );
                }
            );

            $field.find(scope+"__copy").click(
                function(ev){
                    ev.preventDefault();
                    copyValue();
                }
            );

            $field.find(scope+"__paste").click(
                function(ev){
                    ev.preventDefault();
                    var key = getCopySeed();
                    var val = JSON.parse(localStorage[key]);
                    //conversion between multiple/single selectors or single items copied
                    if($field.data("multiple") && $.isPlainObject(val)){
                        addValue(val);
                    }else{
                        if(!$field.data("multiple") && $.isArray(val)){
                            val = val[0];
                        }
                        setValue(val);
                    }
                }
            );

            $input.keyup(
                function(ev){
                    gvf.delay(
                        500,
                        function(){
                            gvf.endpoint(
                                $field.data("endpoint")+"ListContent",
                                [$field.data("model"),{q:$input.val()},0,"tableContent",$field.data(),"model-selector-quick-search"],
                                function(resp){
                                    if(resp.status=="ok"){
                                        var itemScope = ".js-simple-list-page__tr";
                                        $suggest.html($(resp.html));
                                        $suggest.show();
                                        $suggest.find(itemScope).click(
                                            function(ev){
                                                ev.preventDefault();
                                                addValue(simpleListPage.getItemId($(this)));
                                                $suggest.hide();
                                                $input.val("");
                                            }
                                        ).mousedown(
                                            function(ev){
                                                ev.preventDefault();
                                                ev.stopPropagation();
                                            }
                                        );
                                    }
                                }
                            );
                        }
                    );
                }
            ).keydown(
                function(ev) {
                    var codeK = ev.keyCode || ev.which;
                    if (codeK == 13) { //Enter
                        ev.preventDefault();
                    }
                }
            ).blur(
                function(){
                    $suggest.hide();
                }
            ).on("focus click",
                function(){
                    $suggest.show();
                }
            );

            //initial load
            if($value.val() && $info.html()==""){
                setValue($value.val(),true);
            }
		}
	);
}();