//form fields
ui.formFieldDate = function(){
	var scope = ".js-form-field-date";

	ui.ready(
		scope,
		function($field){
			var $input = $field.find(scope+"__input");
            var $inputValue = $field.find(scope+"__input-value");
            var $emptyButton = $field.find(scope+"__empty");
			var options = {
                showDropdowns: true,
                singleDatePicker: true,
                autoUpdateInput:false,
                locale:{
                    format: "DD/MM/YYYY",
                    separator: " - ",
                    applyLabel: "Apply",
                    cancelLabel: "Cancel",
                    fromLabel: "From",
                    toLabel: "To",
                    customRangeLabel: "Customize",
                    weekLabel: "W",
                    firstDay: 1,
                    daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                }
            };
			if($field.hasClass("is-date-time")){
                options.timePicker = true;
                options.timePicker24Hour = true;
                options.locale.format = "DD/MM/YYYY H:mm";
			}else if($field.hasClass("is-date-range")){
                options.singleDatePicker = false;
                options.opens = "center";
			}

            $input.daterangepicker(options);
			//disable events if readonly or disabled
			if($input.prop("readonly")){
                $input.css("pointer-events","none");
            }

			$field.data(
				"funcGetVal",
				function(){
					return $inputValue.val();
				}
			);
            $field.data(
                "funcSetVal",
                function(val){
                    if(val){
                        var picker = $input.data('daterangepicker');
                        if($field.hasClass("is-date-range")){
                            var range = $.isArray(val)?val:JSON.parse(val);
                            picker.setStartDate(moment(range[0]).format(options.locale.format));
                            picker.setEndDate(moment(range[1]).format(options.locale.format));
                        }else{
                            picker.setStartDate(moment(val).format(options.locale.format));
                        }
                        applyInputFormat(picker);
                    }else{
                        $input.val("");
                        $inputValue.val("");
                        $field.trigger("formField:changeVal");
                    }
                }
            );

            $field.find(scope+"__empty").click(
                function(ev){
                    ev.preventDefault();
                    ev.stopPropagation();
                    $field.formFieldSetVal("");
                }
            );

			function applyInputFormat(picker){
                if($field.hasClass("is-date-range")){
                    $input.val(picker.startDate.format(options.locale.format)+" - "+picker.endDate.format(options.locale.format));
                }else{
                    $input.val(picker.startDate.format(options.locale.format));
                }
			}

            $input.on(
            	"apply.daterangepicker",
                function(ev,picker){
            		var format = 'YYYY-MM-DD';
                    if($field.hasClass("is-date-time"))
                        format = 'YYYY-MM-DD HH:mm:00';

                    if($field.hasClass("is-date-range")){
                        $inputValue.val(JSON.stringify([picker.startDate.format(format),picker.endDate.format(format)]));
					}else{
                        $inputValue.val(picker.startDate.format(format));
					}
                    applyInputFormat(picker);
                    $field.trigger("formField:changeVal");
                }
            );
            if($inputValue.val()){
                $field.formFieldSetVal($inputValue.val());
			}

            $emptyButton.prop("disabled",$field.hasClass("is-disabled"));

            $field.on(
                "formField:bindDisable formField:bindEnable",
                function(){
                    $emptyButton.prop("disabled",$field.hasClass("is-disabled"));
                }
            );

            $field.on(
                "change",
                function() {
                    var picker = $input.data('daterangepicker');
                    picker.clickApply();
                });
		}
	);
}();