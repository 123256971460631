var loginPage = function(){
	var scope = ".js-login-page";
	ui.ready(
        scope,
		function($login){
            var $loginForm = $login.find(scope+"__login-form");
        	var $recoverForm = $login.find(scope+"__recover-form");
        	$login.find(scope+"__recover-link").click(
            	function(ev){
            		ev.preventDefault();
                    $recoverForm.find("[name=email]").val($loginForm.find("[name=email]").val());
                    $loginForm.slideUp(
                        function(){
                            $recoverForm.slideDown();
                        }
                    );
				}
			);

            $loginForm.submit(
                function(ev){
                    var isOk = $loginForm.checkRestrictions();
                    if(!isOk){
                        ev.preventDefault();
                    }
                }
			);

            $recoverForm.submit(
                function(ev){
                    ev.preventDefault();
                    var $button = $recoverForm.find(scope+"__recover-button");
                    $button.prop("disabled",true);
                    gvf.endpoint(
                        "\\controller\\cp\\loginController::resetPassRequest",
                        [$recoverForm.getValues()],
                        function(response){
                            $button.prop("disabled",false);
                            utils.alertEndpointResponse(response);
                        }
                    );
                }
            );

            $recoverForm.find(scope+"__recover-cancel-button").click(
                function(ev){
                    ev.preventDefault();
                    $recoverForm.slideUp(
                        function(){
                            $loginForm.slideDown();
                        }
                    );
                }
            );
		}
	);
}();

