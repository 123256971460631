var page = function(){
    var scope = ".js-page";
    var zIndex = 1050;

    function showLoading(){
        var $page = $(scope);
        $page.addClass("is-starting-loading");
        gvf.delay(
            500,
            function(){
                if($page.hasClass("is-starting-loading"))
                    $page.addClass("is-loading");
            }
        );
    }

    function hideLoading(){
        $(scope).removeClass("is-starting-loading is-loading");
    }

    ui.ready(
        scope,
        function($page){
            if($page.data("popup-message")){
                swal(
                    {
                        type: "info",
                        title: "",
                        text: $page.data("popup-message")
                    }
                );
            }

            $page.find(scope+"__logout").click(
                function(ev){
                    ev.preventDefault();
                    showLoading();
                    gvf.endpoint(
                        "\\controller\\cp\\cpController::logout",
                        [],
                        function(response){
                            hideLoading();
                            if(response.status=="ok")
                                window.location = response.url;
                            else
                                utils.alertEndpointResponse(response);
                        }
                    );
                }
            );

            //Nested modals scroll fix
            $(document).on(
                'hide.bs.modal',
                '.modal',
                function(){
                    zIndex--;
                    if($('.modal:visible').length>0){
                        $(document.body).addClass('modal-open');
                    }
                    $(".modal-backdrop").css("z-index",zIndex-1);
                }
            );
            $(document).on(
                'show.bs.modal',
                '.modal',
                function(ev){
                    zIndex++;
                    $(ev.target).css("z-index",zIndex);
                }
            );
            $(document).on(
                'shown.bs.modal',
                '.modal',
                function(ev){
                    $(".modal-backdrop").css("z-index",zIndex-1);
                }
            );
        }
    );

    return {
        showLoading:showLoading,
        hideLoading:hideLoading
    };
}();