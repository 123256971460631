//form fields
ui.formFieldMultiselect = function(){
	var scope = ".js-form-field-multiselect";

	ui.ready(
		scope,
		function($field){
		    var $select = $field.find(scope+"__select");

		    var orderedValue = $field.data("ordered-value");
            if(orderedValue){ //reorder options to keep original order
                for(var i = orderedValue.length;i>=0;i--){
                    $select.find("[value='"+orderedValue[i]+"']").prependTo($select);
                }
            }
            $select.multiSelect(
                {
                    keepOrder:true,
                    afterSelect:function(){
                        //options indexed by text
                        var options = [];
                        $select.find("option").each(
                            function(){
                                options[$(this).text()] = $(this);
                            }
                        );

                        //reorder options to keep order
                        $field.find(".ms-elem-selection").each(
                            function(){
                                if(options[$(this).text()]){
                                    options[$(this).text()].appendTo($select);
                                }
                            }
                        );
                    }
                }
            );

			$field.data(
                "funcGetVal",
                function(){
                    return $select.val();
                }
            );

            $field.data(
                "funcSetVal",
                function(val){
                    $select.val(val);
                    $select.multiSelect('refresh');
                }
            );
		}
	);
}();